* {
  scroll-behavior: smooth;
}

select {
  cursor: pointer;
}

.flexAndCenter {
  display: flex;
  justify-content: center;
  border: 2px dotted var(--primary);
  padding: 1.7px 0px;
  align-items: center;
  height: 37px;
}

.reload-captcha-icon {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: var(--primary);
  border-radius: 5px;
  color: white;
  height: 37px;
  /* cursor: pointer; */
}

.rotate {
  transform: rotate(360deg);
  transition: 0.3s ease-in-out;
}

#captcha-wrapper {
  height: fit-content;
}

.captchaImage {
  height: 37px;
}

.page-not-found h1 {
  font-size: 60px;
  margin-top: 38vh;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* Theme colors */
:root {
  --danger: #f63203;
  --primary: #00368b;
}

/* scroll to top button */
.scroll-top {
  position: fixed;
  right: 20px;
  bottom: 50px;
  height: 20px;
  font-size: 2.5rem;
  z-index: 999;
  cursor: pointer;
  color: var(--danger);
}

/* React toast */
.toast-container {
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  transform: translateX(-50%) translateY(-50%) !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  padding-top: 21% !important;
  padding-right: 38% !important;
  padding-left: 38% !important;
  height: 100%;
  width: 100% !important;
}

/* Padding and height for all component's parent div  */
.padding-and-height-for-main-container {
  padding: 30px 0;
  min-height: 100vh !important;
  /* overflow: hidden !important; */
}

/* Heading color for all components */
.primary-color-heading {
  color: var(--primary);
}

/* Button styles for all components */
.primary-btn {
  background: var(--primary) !important;
  color: white !important;
  transition: 0.3s ease-in-out !important;
}

.primary-btn:hover {
  background: white !important;
  color: var(--danger) !important;
  border-color: var(--danger) !important;
  transition: 0.3s ease-in-out !important;
}

/* Styles for Navbar.js */
.navbar {
  background: white !important;
  box-shadow: 0 0 4px 0;
}

.navbar-collapse {
  transition: 0.6s ease-in-out !important;
}

.navbar-brand {
  width: 144px !important;
  height: 61px !important;
}

.nav-link {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: black !important;
  align-items: center;
  display: flex;
  height: 100%;
}

.navbar-toggler {
  box-shadow: none !important;
  border: none !important;
}

.menu-button {
  width: 27px;
  height: 27px;
}

.nav-item > .active,
.nav-item > .active:hover {
  color: var(--danger) !important;
  border-bottom: 3px solid var(--danger);
}

.nav-link:hover {
  color: var(--danger) !important;
  cursor: pointer;
}

/* Styles for Home.js */

.home .carousel-inner {
  height: 635px;
}

.home .carousel-inner img {
  object-fit: fill !important;
}

.home .carousel-inner .carousel-homeImg {
  filter: brightness(0.5);
}

.home .carousel-caption-homeImg h3 {
  font-weight: 900 !important;
  font-size: 4.4rem !important;
}

.home .carousel-caption-homeImg p {
  padding-top: 20px;
  font-size: 35px;
}

.home .carousel-caption-homeImg {
  margin-bottom: 18rem;
  width: 100%;
  left: 0 !important;
  right: 0 !important;
}

.home .indicators {
  background-color: var(--danger) !important;
  margin: 2rem 5px !important;
}

/* Features */
#features .title {
  height: 480px;
  text-align: left;
  padding-top: 100px;
  background: url("./images/bg.jpg") no-repeat center center;
  background-size: cover;
}

.feature-img img {
  height: auto;
  max-width: 100%;
}

.margin-top-up {
  margin-top: -7rem;
}

.single-feature {
  background: white;
  text-align: center;
  margin-top: 2rem;
  padding: 2.5rem;
  position: relative;
  border-radius: 1.6rem;
  /* transition: 0.2s ease-in; */
  box-shadow: 0 0.9rem 2.5rem 0 rgba(0, 0, 0, 0.08);
}

.single-feature:hover {
  color: white;
  background: var(--primary);
}

.feature-icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  font-size: 23px;
  border-bottom-left-radius: 25px;
  background: var(--danger);
}

/* Slider    */

section div.container {
  transition: all 0.3s ease;
}

section.slide-option {
  margin: 10 0 10px 0;
}

section.slide-option .no-marg {
  margin: 0 0 0 0;
}

div.highway-slider {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 150px;
}

div.highway-slider div.highway-barrier {
  overflow: hidden;
  position: relative;
}

div.highway-slider ul.highway-lane {
  display: flex;
  height: 100%;
  margin: 0;
}

div.highway-slider ul.highway-lane li.highway-car {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #343434;
}

@-webkit-keyframes translatestf {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-500%);
  }
}

@keyframes translatestf {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-500%);
  }
}

#stffull div.highway-barrier ul.highway-lane {
  width: 500%;
}

#stffull div.highway-barrier ul.highway-lane li.highway-car {
  -webkit-animation: translatestf 30s linear infinite;
  animation: translatestf 30s linear infinite;
}

#stffull div.highway-barrier ul.highway-lane li.highway-car h4 {
  font-size: 28px;
}

@-webkit-keyframes translateinfinite {
  100% {
    transform: translateX(calc(-180px * 12));
  }
}

@keyframes translateinfinite {
  100% {
    transform: translateX(calc(-180px * 12));
  }
}

/* Home-AboutUs */
.home-about-img {
  border-radius: 30px;
}

/* Home-services */

/* Home Technologies */
.home-technology-btn-div {
  padding-top: 42px;
}

.technology-details .card {
  transition: 0.3s ease-in-out;
  height: 438px;
}

.technology-details .card:hover {
  border-color: transparent;
  box-shadow: 0px 54px 115px 0px rgb(52 60 66 / 25%) !important;
}

.home-technology-btn-div a {
  width: fit-content;
}

/* Styles for AboutUs.js */
.about-text {
  text-align: justify;
  font-size: 17px;
  line-height: 1.8rem;
}

.our-team h5 {
  font-size: 16px !important;
}

/* Vision mission */
#vision-mission .card {
  height: 395px !important;
  overflow: hidden;
}

#vision-mission .card-text {
  font-size: 15px;
}

#vision-mission .card:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

#vision-mission .card:hover img {
  transition: all 0.7s ease;
  transform: scale(1.05);
}

/* Customers */
.customers-slides .swiper-slide {
  width: 265px;
  height: auto;
}

.customers-slides .swiper-slide .card {
  height: 130px;
  padding: 15px;
  margin-bottom: 4rem;
  border: none;
}

.customers-slides .swiper-slide .card img {
  height: 100%;
  width: 100%;
}

/* Current Engagement */

.info-card h5 {
  text-transform: uppercase;
}

.info-card {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 1.5rem;
  padding: 1.8rem 2.5rem 2.5rem;
  position: relative;
  overflow: hidden;
  height: 340px;
  transition: 0.2s ease-out;
  will-change: transform;
}

.info-card::before {
  position: absolute;
  content: "";
  width: 0.06rem;
  height: 0.19rem;
  top: 0rem;
  left: 0rem;
  border-radius: 50%;
  transition: 0.2s ease-out;
}

.info-card:hover::before {
  width: 100%;
  background: var(--danger);
}

.info-card:hover {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.64);
}

/* Our Team */
.our-team .card {
  transition: 0.3s ease-in-out;
  height: 370px;
}

.our-team .card:hover,
.our-team .card:focus {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}

.our-team .card:hover .img-holder img {
  transform: scale(1.05);
  filter: brightness(1) contrast(1.1);
  transition: 0.3s ease-in-out;
}

.our-team .img-holder {
  overflow: hidden;
  height: 270px !important;
}

.our-team .img-holder img {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
  object-position: center 0px;
}

.our-team .designationPara {
  font-size: 12px !important;
  font-weight: 400 !important;
}

/* Styles for Services.js */
.services .card-text,
.home-services .card-text {
  font-size: 15px;
  margin-top: 0.4rem;
}

.services img,
.home-services img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

/* Styles for Technologies.js */

.technologies-slides .swiper-slide {
  width: 265px;
  height: auto;
}

.technologies-slides .swiper-slide .card {
  height: 100%;
  width: 100%;
}

.technologies-slides .swiper-pagination {
  position: relative !important;
  margin-top: 1rem;
}

.technologies-slides .swiper-slide .card h5 {
  visibility: hidden;
  padding-bottom: 2rem;
}

.technologies-slides .icon-transition svg,
.home-technology-component svg {
  transition: 0.5s ease-in-out;
  margin-bottom: 2rem;
  height: 75px;
  width: 75px;
}

.hidden-title-transition {
  transform: scale(0.1);
  transition: 0.5s ease-in-out;
}

.visible {
  visibility: visible;
  transform: scale(1.2);
  transition: 0.5s ease-in-out;
}

.technologies-slides .swiper-slide .card,
.home-technology-container .card {
  border: none;
}

.icon-active-transition {
  transform: scale(1.5);
  transition: 0.5s ease-in-out;
}

/* Technology Details */
.technology-details .technology-icon svg,
.home-technology-icon svg {
  transition: 1s ease-in-out;
  height: 85px;
  width: 85px;
  margin: 1rem 0;
}

/* Styles for Careers.js */
.careers .careerPage-heading {
  color: #fff;
}

.noJobFound {
  padding-top: 88px;
}

.career-top-container {
  background: url("./images/careers1.jpg") no-repeat center;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}

.overlay {
  background-color: rgb(0 0 0 / 60%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.career-section-top {
  position: relative;
}

/* Search box for job */

.search-container {
  position: relative;
  width: 100%;
  text-align: center;
  margin: 0px auto;
  height: 42px;
  overflow: hidden !important;
  margin-bottom: 1rem;
  background-color: white;
  border-radius: 0.375rem;
  border: 1px solid rgb(184, 184, 184);
}

.search-container input {
  position: absolute;
  top: 0;
  left: 0;
}

.search-container input {
  width: 100%;
  padding: 5px 20px;
  padding-right: 50px;
  border-radius: 0.375rem;
  outline: none;
  font-size: 19px;
  background: none;
  border: none;
}

.search-container button {
  background: transparent;
  border: none;
  outline: none;
  position: absolute;
  top: 6px;
  right: 10px;
}

.search-container button svg {
  font-size: 24px;
  color: rgb(190, 190, 190);
}

/* Pagination */
.page-link {
  padding: 0.1rem 0.5rem !important;
  color: var(--primary) !important;
  box-shadow: none !important;
}

.page-link:hover {
  background: var(--primary) !important;
  color: white !important;
}

.active > .page-link {
  background: var(--primary) !important;
  border-color: var(--primary) !important;
  color: white !important;
  box-shadow: none !important;
}

.disabled > .page-link {
  color: gray !important;
}

/* Jobs and job description */
.marginOnscroll {
  margin: 6rem 0;
}

.job-card .card-body p {
  font-size: 12px;
  margin-bottom: 4px !important;
}

.job-card .job-added-time .text-muted {
  font-size: 11px;
}

.active-job {
  border: 1px solid var(--primary) !important;
}

.job-card:hover {
  cursor: pointer;
}

.job-card .card-body {
  color: black !important;
}

.job-card .card-title,
.job-description .card-title {
  color: var(--danger);
}

.job-description-card .card-head {
  position: absolute;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 4px solid rgba(169, 169, 169, 0.4);
}

.job-description-card .card-body {
  overflow-y: scroll;
  height: 60vh;
  margin-top: 11rem !important;
}

.job-description-card .card-body p,
span {
  font-size: 14px;
}

.job-description-card .card-head p {
  margin-bottom: 0;
}

/* Subscribe section */
.newsletter {
  width: 70%;
  margin: 2rem 0;
}

.subscribe-btn {
  padding: 0.63rem 1.88rem !important;
  font-weight: 600 !important;
}

.newsletter .form-control {
  height: 2.8rem;
  padding: 0 1.25rem;
  font-size: 0.88rem;
  width: 100%;
  border-radius: 5px;
  background: transparent;
  border: 0.19rem solid white;
  color: black;
  background: white;
  /* font-weight: 700; */
}

.newsletter .form-control:hover,
.newsletter .form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--primary);
}

/* Link for job application form */
.candidate-form-link:hover {
  color: var(--danger);
}

/* Padding for location filter select */
.px-4-on-small-screen .form-select {
  padding: 8px 1.4rem !important;
}

/* Contact us Form Styles */

.react-tel-input {
width: 100% !important;
}


.react-tel-input .special-label {
  display: none !important;
}

.react-tel-input .form-control {
  padding: 0.5rem !important;
}

.gmap_iframe {
  width: 100% !important;
  height: 130px;
}

.custom-office-row > * {
  width: unset !important;
}

.office-title h5 {
  margin: 0 !important;
}

.contact .form-control {
  box-shadow: none !important;
}

.office-address-link-card-1,
.office-address-link-card-2 {
  text-decoration: none;
  color: var(--primary) !important;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: all 0.5s ease;
}

.expand-arrow-1,
.expand-arrow-2 {
  transition: all 0.5s ease;
}

.column2 {
  position: sticky;
  height: fit-content;
  top: 140px;
}

#mySelect {
  width: 116px !important;
  text-align: start;
  border: 1px solid darkgray;
}

.address-row .col-1 {
  width: 5% !important;
}

/* Footer */
footer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--primary);
  padding-top: 5rem;
}

.office-1,
.office-2 {
  cursor: pointer;
  padding-bottom: 8px;
}

.address-display-none {
  display: none;
}

.border-and-color-visible {
  border-bottom: 2px solid white;
  /* color: var(--danger); */
}

.office-1:hover,
.office-2:hover {
  color: var(--danger);
}

.footer-logo-div {
  margin-top: -13px !important;
}

.footer-logo {
  width: 100% !important;
}

footer h5 {
  margin-bottom: 1.6rem;
}

footer p {
  color: darkgray !important;
}

.explore-link {
  text-decoration: none;
  color: darkgray !important;
}

.explore-link:hover,
footer .icons:hover {
  color: var(--danger) !important;
  cursor: pointer;
}

/* Resources */
.resources .card:hover {
  cursor: pointer;
  background: var(--danger);
  color: white;
}

/* resource home */

.resource-home-container {
  min-height: 100vh;
  margin-top: 100px;
}

.resource-home-container > h1 {
  color: var(--primary);
  margin-bottom: 1rem;
}

.resource-home-container ul li {
  font-size: 22px;
  margin: 20px;
  list-style: circle;
}

.resource-home-container .resource-link {
  text-decoration: none;
  color: rgb(0, 0, 0) !important;
  cursor: pointer !important;
}

.resource-home-container .resource-link:hover {
  color: rgb(80, 80, 242) !important;
}

.main-content .button-container {
  width: 100%;
  text-align: right;
}

.main-content .button-container button {
  padding: 5px 25px;
  margin: 5px 15px;
  text-align: center;
  outline: none;
  border: none;
  background: none;
}

.main-content .button-container button svg {
  font-size: 30px !important;
  color: var(--primary);
  /* color:#bc12db; */
  transition: all 0.5s;
}

.main-content .button-container button:hover {
  background-color: transparent;
  border: none;
}

.main-content .button-container button:hover .upload-button {
  /* color: blue; */
  color: var(--danger);
  transform: scale(1.2);
}

.main-content .button-container button:hover .del-button {
  color: red;
  transform: scale(1.5);
}

.all-policy {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.all-policy .policy-div {
  cursor: pointer;
  width: 260px;
  height: max-content;
  border-radius: 5px;
  border: 1px solid rgb(191, 191, 191);
  overflow: hidden;
  margin: 15px;
  transition: all 0.5s;
}

.all-policy .policy-div:hover {
  transform: scale(1.1);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.all-policy .policy-div .image-div img {
  width: 100%;
  height: 180px;
}

.all-policy .policy-div .text-div p {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin: 5px;
}

/* login page part */
.bg-image-vertical {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 100%;
}

/* country togle button */
.country {
  position: fixed;
  top: 74px;
  right: 0px;
  z-index: 999;
}

.country .btn {
  outline: none;
  border: none;
}

.country .dropdown-menu {
  min-width: 0rem !important;
  padding: 0px;
  border: none;
  padding-top: 5px;
}

/* resource create form css */
.contact-form {
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.drag-area {
  border: 2px dashed #231770;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 275px;
}

.drag-area.active {
  border: 2px solid #231770;
}

.drag-area .icon {
  font-size: 30px;
  color: #231770;
}

.drag-area header {
  font-size: 20px;
  font-weight: 500;
  color: #231770;
}

.drag-area span {
  font-size: 17px;
  font-weight: 500;
  color: #231770;
}

.drag-area button {
  padding: 4px 6px;
  font-size: 17px;
  font-weight: 500;
  border: none;
  outline: none;
  background: #231770;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

/* create List page */
.get-in-touch {
  max-width: 800px;
  margin: 50px auto;
  position: relative;
}

.get-in-touch .title {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 3em;
  line-height: 48px;
  padding-bottom: 48px;
  color: var(--primary);
}

.contact-form .form-field {
  position: relative;
  margin: 12px 0;
}

.contact-form .input-text {
  display: block;
  width: 100%;
  height: 36px;
  border-width: 0 0 2px 0;
  border-color: #5543ca;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  outline: none;
}

.contact-form .input-radio {
  display: block;
  width: 100%;
  height: 24px;
  border-width: 0 0 2px 0;
  border-color: #5543ca;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
}

.contact-form .label {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #5543ca;
  cursor: text;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.contact-form .radio-div .label {
  position: relative;
}

.contact-form .submit-btn {
  display: inline-block;
  background-color: #000;
  background-image: linear-gradient(125deg, #a72879, #064497);
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  padding: 8px 16px;
  border: none;
  width: 200px;
  cursor: pointer;
}

/* pdf reader css */
.outer-pdf-container {
  position: absolute;
  top: 6rem;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  /* height: 100vh !important; */
}

.pdf-container {
  width: 90%;
  margin: 0 auto;
}

.pdf-container .main-pdf-div {
  width: 100%;
  height: 500px;
  background-color: #e4e4e4;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-header {
  background-color: rgb(71, 66, 66);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
}

.pdf-header .left-pdf-header p {
  margin: 0;
  font-size: 30px;
  font-weight: 700px;
}

.pdf-header .right-pdf-header button {
  padding: 7px 15px;
  font-size: 20px;
  font-weight: 500;
  color: white;
  border: none;
  border-radius: 5px;
  background: none;
  cursor: pointer;
}

.pdf-header .right-pdf-header button:hover {
  background-color: white;
  color: black;
}

.pdf-container iframe {
  width: 99.6%;
  min-height: 700px;
}

/* Animation */

.reveal {
  position: relative;
  opacity: 0;
  transition: fade 1s ease-in-out;
}

.overflowHide {
  overflow: hidden;
}

.reveal.active {
  opacity: 1;
}

.active.fade-blink {
  animation: fadeBlink 1s ease;
}

.active.fade-in {
  animation: fade 0.6s ease;
}

.active.fade-up {
  animation: fadeInUp 0.6s ease;
}

.active.fade-bottom {
  animation: fade-bottom 0.6s ease;
}

.active.fade-left {
  animation: fade-left 0.6s ease;
}

.active.fade-right {
  animation: fade-right 0.6s ease;
}

/* @keyframes fade {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  80% {
    transform: scale(1.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
} */

@keyframes fadeBlink {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    transform: translate3d(0, 60px, 0);
    opacity: 0;
    filter: blur(10px);
  }

  50% {
    filter: blur(5px);
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    filter: blur(1px);
  }
}

@keyframes fade-bottom {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Media Queries */

@media (max-width: 1024px) {
  .home .carousel-caption-homeImg {
    margin-bottom: 15.1rem !important;
  }

  .home .carousel-caption-homeImg h3 {
    font-size: 3.8rem !important;
  }

  .home .carousel-caption-homeImg p {
    padding-top: 0px;
    font-size: 1.3rem;
  }

  .home .carousel-inner {
    height: 635px;
  }

  .home .carousel-inner img {
    height: 635px;
  }

  .technology-details .card {
    height: 390px !important;
  }

  .our-team .img-holder {
    height: 225px !important;
  }

  .our-team .card {
    height: 325px;
  }

  .captcha-inputBox {
    padding-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .col-custom-5 {
    flex: 0 0 auto;
    width: 20% !important;
  }
}

@media (max-width: 992px) {
  .text-center-smallScreen {
    text-align: center;
  }
  .emp-modal {
    max-width: 90% !important;
  }
  .primary-btn:hover {
    background: var(--primary) !important;
    color: white !important;
    border-color: var(--primary) !important;
  }

  .toast-container {
    padding-right: 30% !important;
    padding-left: 30% !important;
  }

  /* Navbar */
  .navbar {
    text-align: center;
  }

  .nav-item > .active,
  .nav-item > .active:hover {
    border-bottom: none;
  }

  /* .country {
    position: fixed;
    top: 60px;
  } */

  /* Home */
  .home .carousel-inner {
    height: 435px;
  }

  .home .carousel-inner img {
    height: 435px !important;
  }

  .home .carousel-caption-homeImg {
    margin-bottom: 10rem !important;
  }

  .home .carousel-caption-homeImg h3 {
    font-size: 2.8rem !important;
  }

  .home .carousel-caption-homeImg p {
    padding-top: 0px;
    font-size: 1.5rem;
  }

  /* Home AboutUs */
  .home-about-img {
    padding: 0;
  }

  /* About */
  #vision-mission .card {
    height: 350px;
  }

  .customers .owl-carousel .item {
    height: 70px !important;
  }

  .our-team .card {
    height: 391px;
    text-align: center;
  }

  .our-team .img-holder {
    height: 290px !important;
  }

  .our-team p {
    font-size: 14px;
  }

  #infinite div.highway-barrier::before {
    background: none !important;
  }

  #infinite div.highway-barrier::after {
    background: none !important;
  }

  /* Technology */

  .technology-details .card {
    height: 390px;
  }

  /* Careers */
  .job-description-card .card-body {
    height: 30vh;
  }

  .newsletter {
    width: 80%;
  }
}

@media (min-width: 991px) {
  .navbar .container-fluid {
    height: 70px;
  }

  .navbar .nav-link {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .navbar #navbarNav {
    height: 100%;
  }

  .navbar ul {
    height: 100%;
  }
}

@media (max-width: 767px) {
  .emp-modal {
    max-width: 100% !important;
  }

  .page-not-found h1 {
    font-size: 35px;
  }

  .toast-container {
    padding-right: 11% !important;
    padding-left: 11% !important;
  }

  .margin-top-up {
    margin-top: -4rem;
  }

  .home .carousel-caption-homeImg {
    margin-bottom: 10.1rem !important;
  }

  .home .carousel-caption-homeImg h3 {
    font-size: 2.8rem !important;
  }

  .home .carousel-caption-homeImg p {
    padding-top: 0px;
    font-size: 1.3rem;
  }

  .home .indicators {
    margin: 0.5rem 5px !important;
  }

  /* home Feature */
  #features .title {
    padding-top: 50px;
  }

  .current-engagement .info-card {
    height: fit-content;
  }

  /* Home AboutUs */
  /* Home-services */

  /* Home-technology */
  .home-technology-component svg {
    width: 70px !important;
    height: 70px !important;
  }

  .home-technology-container .card-title,
  .home-technology-component {
    text-align: center;
  }

  .home-technology-container .row {
    justify-content: center !important;
  }

  .home-technology-btn-div a {
    width: fit-content;
  }

  /* About */
  .about-text {
    font-size: small;
  }

  /* mission vision values */

  #vision-mission .card {
    height: fit-content !important;
  }

  .our-team .card {
    height: 416px;
    text-align: center;
  }

  .our-team .img-holder {
    height: 335px !important;
  }

  .technology-details .card {
    height: fit-content !important;
  }

  .technology-details .info-card {
    height: fit-content;
  }

  /* Careers */
  .job-description-card .card-body {
    height: 50vh;
  }

  .px-4-on-small-screen {
    padding: 0 22px !important;
  }

  .newsletter {
    width: 100%;
  }

  .newsletter .subscribe-btn {
    width: 100%;
    margin-top: 1rem;
  }
}

@media (max-width: 515px) {
  .home .carousel-inner {
    height: 220px;
  }

  .home .carousel-inner img {
    height: 220px !important;
  }

  .home .carousel-caption-homeImg {
    margin-bottom: 2.1rem !important;
  }

  .home .carousel-caption-homeImg h3 {
    font-size: 1.2rem !important;
  }

  .home .carousel-caption-homeImg p {
    padding-top: 0px;
    font-size: 0.7rem;
  }

  .our-team .col-6 {
    width: 100% !important;
    padding: 0px 48px;
  }

  .our-team .card {
    height: fit-content;
    text-align: center;
  }

  .our-team .img-holder {
    height: fit-content !important;
  }
  .our-team .img-holder img {
    object-fit: contain !important;
  }

  .our-team .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

#infinite div.highway-barrier {
  background: #fff;
  /* box-shadow: 0 3px 10px -3px rgba(0, 0, 0, 0.3); */
}

#infinite div.highway-barrier::before,
#infinite div.highway-barrier::after {
  content: " ";
  position: absolute;
  z-index: 9;
  width: 180px;
  height: 100%;
}

#infinite div.highway-barrier::before {
  top: 0;
  left: 0;
  background: linear-gradient(
    to right,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

#infinite div.highway-barrier::after {
  top: 0;
  right: 0;
  background: linear-gradient(to left, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}

#infinite div.highway-barrier ul.highway-lane {
  width: calc(180px * 24);
  align-items: center;
}

#infinite div.highway-barrier ul.highway-lane li.highway-car {
  height: 100px;
  animation: translateinfinite 25s linear infinite;
  padding: 10px;
  margin: 0px 15px;
}

#infinite div.highway-barrier ul.highway-lane li.highway-car img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  filter: grayscale(1);
}

#infinite div.highway-barrier ul.highway-lane li.highway-car img:hover {
  filter: none;
}

#red {
  background: #cb5454;
}

#orange {
  background: #cb8054;
}

#yellow {
  background: #cbba54;
}

#green {
  background: #69b46e;
}

#blue {
  background: #6091b0;
}
